<div class="row">
  <div class="col">
    <div class="h-100 p-4 bg-body-tertiary border rounded-3">
      <h4 class="card-title d-flex justify-content-between mb-4">
        <span>{{ blogEntry.headline }}</span>
        <span class="small">{{ blogEntry.date }}</span>
      </h4>

      <div *ngFor="let paragraph of blogEntry.paragraphs">
        <h6
          class="card-subtitle mb-2 text-body-secondary"
          *ngIf="paragraph.headline"
        >
          <strong>{{ paragraph.headline }}</strong>
        </h6>

        <p>
          {{ paragraph.content }}
        </p>
      </div>
    </div>
  </div>
</div>
