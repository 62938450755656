import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject } from 'rxjs';

import { CookieConsentBannerComponent } from '../../components/cookie-consent-banner/cookie-consent-banner.component';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  cookiesAccepted$ = new BehaviorSubject<boolean>(false);
  isBrowser = false;

  constructor(
    private snackbar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  openCookieBanner() {
    if (this.isBrowser) {
      if (
        JSON.parse(localStorage.getItem('cookieConsent') ?? 'false') === false
      ) {
        this.snackbar.openFromComponent(CookieConsentBannerComponent);
        this.cookiesAccepted$.next(false);
      } else {
        this.cookiesAccepted$.next(true);
      }
    }
  }

  accept() {
    if (this.isBrowser) {
      localStorage.setItem('cookieConsent', JSON.stringify(true));
      this.cookiesAccepted$.next(true);
    }
  }
}
