import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CookieConsentService } from '../../services/cookie-consent/cookie-consent.service';

@Component({
  selector: 'metall-rechner-cookie-consent-banner',
  templateUrl: './cookie-consent-banner.component.html',
  styleUrls: ['./cookie-consent-banner.component.scss']
})
export class CookieConsentBannerComponent {
  constructor(private snackbar: MatSnackBar, private cookieConsentService: CookieConsentService) {}

  accept() {
    this.cookieConsentService.accept();
    this.snackbar.dismiss();
  }

  decline() {
    this.snackbar.dismiss();
  }
}
