import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogRoutingModule } from './blog-routing.module';
import { ContainerComponent } from './container/container.component';
import { BlogComponent } from './blog/blog.component';
import { BlogEntryComponent } from './blog-entry/blog-entry.component';
import { LabourAgreementsComponent } from './labour-agreements/labour-agreements.component';

@NgModule({
  declarations: [
    ContainerComponent,
    BlogComponent,
    BlogEntryComponent,
    LabourAgreementsComponent,
  ],
  imports: [CommonModule, BlogRoutingModule],
})
export class BlogModule {}
