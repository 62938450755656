import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { SharedModule } from '@metallgehalt/shared';

import { CalculatorComponent } from './components/calculator/calculator.component';
import { ContainerComponent } from './components/container/container.component';
import { MonthlyChartComponent } from './components/monthly-chart/monthly-chart.component';
import { AboutLaborAgreementComponent } from './components/about-labor-agreement/about-labor-agreement.component';

@NgModule({
  declarations: [
    CalculatorComponent,
    ContainerComponent,
    MonthlyChartComponent,
    AboutLaborAgreementComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    ClipboardModule,
    MatSnackBarModule,
    SharedModule,
    RouterModule,
  ],
  exports: [MatSnackBarModule],
})
export class SalaryCalculatorModule {}
