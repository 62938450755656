<div class="row">
  <div class="col">
    <div class="h-100 p-3 p-md-4 bg-body-tertiary border rounded-3">
      <h2>Tarifrunde 2022</h2>
      <div class="row">
        <div class="col-12 col-lg-6 mb-4 mb-lg-0">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">Inflationsausgleichsprämie</div>
                <span class="text-success">+1500€</span>
              </div>
              <span class="badge bg-primary rounded-pill">bis Feb. 2023</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tabellenwirksame Erhöhung</div>
                <span class="text-success">+5,2%</span>
              </div>
              <span class="badge bg-primary rounded-pill">ab Jun. 2023</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">Inflationsausgleichsprämie</div>
                <span class="text-success">+1500€</span>
              </div>
              <span class="badge bg-primary rounded-pill">bis Feb. 2024</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start px-1 px-md-3"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tabellenwirksame Erhöhung</div>
                <span class="text-success">+3,3%</span>
              </div>
              <span class="badge bg-primary rounded-pill">ab Mai 2024</span>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-6">
          <p>
            Im November 2022 hat die IG Metall in Tarifverhandlungen
            Tabellenwirksame (dauerhafte) Erhöhungen von insgesamt 8,5%
            erreicht. Zusätzlich gibt es insgesamt 3000€
            Inflationsausgleichsprämie. Der Tarifvertrag läuft bis zum 30.
            September 2024.
          </p>
          <p>
            Eine Erhöhung, die im Jahr 2021 beschlossen wurde, wird mit diesem
            Tarifvertrag wieder aufgehoben. Nämlich die Erhöhung des
            Transformationsgeldes (T-Zug T). Ursprünglich sollte diese Zahlung
            im Jahr 2023 auf 27,6% steigen. Sie bleibt allerdings auf 18,4%
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
