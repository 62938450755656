import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

import { debounceTime, delay, zip } from 'rxjs';
import { CookieConsentService } from '@metallgehalt/shared';

import { SalaryService } from '../../services/salary/salary.service';

@Component({
  selector: 'metall-rechner-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent {
  form: FormGroup = this.fb.group({
    labourAgreementId: [null, Validators.required],
    region: ['', Validators.required],
    efficiencyBonus: [
      0,
      [Validators.min(0), Validators.max(150), Validators.required],
    ],
    payGrade: ['', Validators.required],
    payGradeStep: ['', Validators.required],
    weeklyWorkingHours: [
      35,
      [Validators.min(1), Validators.max(50), Validators.required],
    ],
    christmasMoney: [
      55,
      [Validators.required, Validators.min(0), Validators.max(200)],
    ],
  });

  url: string | undefined = undefined;
  sharedParameters: any;
  loading = false;
  labourAgreements: Array<any> = [];
  eraData: any;
  extraPaymentData: any;
  regions: Array<{ key: string; name: string }> = [];
  payGrades: Array<string> = [];
  payGradeSteps: Array<string> = [];

  baseSalary: number = 0;
  monthlyBaseSalary: number = 0;
  extraHoursSalary: number = 0;
  monthlySalary: number = 0;
  efficiencyBonusSalary: number = 0;
  extraPayments: Array<any> = [];
  sum: number = 0;
  labourAgreementInfo!: string;

  chartData: any;

  constructor(
    private fb: FormBuilder,
    private salaryService: SalaryService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public cookieConsentService: CookieConsentService
  ) {}

  ngOnInit() {
    this.onLabourAgreementChange();
    this.onRegionChange();
    this.onPayGradeChange();
    this.onPayGradeStepChange();
    this.onWeeklyWorkingHoursChange();
    this.onChristmasMoneyChange();
    this.onEfficiencyBonusChange();

    this.route.queryParams.subscribe((params) => {
      this.sharedParameters = params;
      this.getLabourAgreements();
    });
  }

  getLabourAgreements() {
    this.salaryService.getLabourAgreements().subscribe((data) => {
      this.labourAgreements = data;
      if (this.sharedParameters?.labourAgreementId) {
        this.form.patchValue({
          labourAgreementId: this.sharedParameters.labourAgreementId,
        });
      }
    });
  }

  onLabourAgreementChange() {
    this.form
      .get('labourAgreementId')
      ?.valueChanges.subscribe((labourAgreementId: string) => {
        this.resetCalculations();
        this.eraData = undefined;
        this.extraPaymentData = undefined;

        const labourAgreement = this.labourAgreements.find(
          (labourAgreement) =>
            `${labourAgreement.id}` === `${labourAgreementId}`
        );
        this.labourAgreementInfo = labourAgreement.info;
        this.getEraData(
          labourAgreement.eraData,
          labourAgreement.extraPayments,
          () => {
            this.chartData = undefined;
          }
        );
      });
  }

  getEraData(
    eraYear: number,
    extraPaymentYear: number,
    clearChart: () => void
  ) {
    this.loading = true;

    zip(
      this.salaryService.getEraData(eraYear),
      this.salaryService.getExtraPayments(extraPaymentYear)
    ).subscribe(([eraData, extraPayments]) => {
      this.loading = false;
      this.eraData = eraData;
      this.regions = Object.entries(eraData).map(([key, regionData]) => ({
        key,
        name: regionData.name,
      }));
      this.extraPaymentData = extraPayments;

      if (
        this.sharedParameters?.region &&
        this.sharedParameters?.efficiencyBonus &&
        this.sharedParameters?.payGrade &&
        this.sharedParameters?.payGradeStep &&
        this.sharedParameters?.weeklyWorkingHours &&
        this.sharedParameters?.christmasMoney
      ) {
        this.form.patchValue({
          region: this.sharedParameters.region,
          efficiencyBonus: this.sharedParameters.efficiencyBonus,
          payGrade: this.sharedParameters.payGrade,
          payGradeStep: this.sharedParameters.payGradeStep,
          weeklyWorkingHours: this.sharedParameters.weeklyWorkingHours,
          christmasMoney: parseFloat(this.sharedParameters.christmasMoney),
        });

        this.sharedParameters = undefined;
      }

      this.calculatePayments();

      if (this.sum === 0) {
        clearChart();
      }
    });
  }

  onRegionChange() {
    this.form.get('region')?.valueChanges.subscribe((region) => {
      this.resetCalculations();
      this.chartData = undefined;
      this.payGrades = Object.keys(this.eraData[region].data);
      this.form.patchValue({
        efficiencyBonus: (this.eraData[region].efficiencyBonus * 100).toFixed(
          0
        ),
      });
      this.form.patchValue(
        { payGrade: '', payGradeStep: '' },
        { emitEvent: false }
      );
    });
  }

  onPayGradeChange() {
    this.form.get('payGrade')?.valueChanges.subscribe((payGrade) => {
      this.resetCalculations();
      this.chartData = undefined;
      const region = this.form.get('region')?.value;

      this.payGradeSteps = Object.keys(this.eraData[region].data[payGrade]);

      if (this.payGradeSteps.length === 1) {
        this.form.patchValue({ payGradeStep: this.payGradeSteps[0] });
      } else {
        this.form.patchValue({ payGradeStep: '' }, { emitEvent: false });
      }
    });
  }

  onPayGradeStepChange() {
    this.form
      .get('payGradeStep')
      ?.valueChanges.pipe(delay(0))
      .subscribe(() => {
        this.calculatePayments();
      });
  }

  onWeeklyWorkingHoursChange() {
    this.form
      .get('weeklyWorkingHours')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe(() => {
        this.calculatePayments();
      });
  }

  onChristmasMoneyChange() {
    this.form.get('christmasMoney')?.valueChanges.subscribe(() => {
      this.calculatePayments();
    });
  }

  onEfficiencyBonusChange() {
    this.form
      .get('efficiencyBonus')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe(() => {
        this.calculatePayments();
      });
  }

  calculatePayments() {
    if (this.form.valid) {
      const region = this.form.get('region')?.value;
      const efficiencyBonus = this.form.get('efficiencyBonus')?.value / 100;
      const payGrade = this.form.get('payGrade')?.value;
      const payGradeStep = this.form.get('payGradeStep')?.value;
      const weeklyWorkingHours = this.form.get('weeklyWorkingHours')?.value;

      this.url = this.generateUrl();
      const workingHoursFactor = weeklyWorkingHours / 35;
      this.baseSalary = this.eraData[region].data[payGrade][payGradeStep];
      this.efficiencyBonusSalary = this.baseSalary * efficiencyBonus;
      this.monthlyBaseSalary = this.baseSalary + this.efficiencyBonusSalary;
      if (workingHoursFactor > 1) {
        this.extraHoursSalary =
          this.monthlyBaseSalary * workingHoursFactor - this.monthlyBaseSalary;
      } else if (workingHoursFactor < 1) {
        this.extraHoursSalary =
          this.monthlyBaseSalary * workingHoursFactor - this.monthlyBaseSalary;
      } else {
        this.extraHoursSalary = 0;
        this.monthlyBaseSalary = this.monthlyBaseSalary * workingHoursFactor;
      }
      this.monthlySalary = this.monthlyBaseSalary + this.extraHoursSalary;

      if (this.form.get('payGrade')?.value === 'Ausbildung') {
        this.monthlySalary = this.baseSalary;
        this.efficiencyBonusSalary = 0;
      }

      this.extraPayments = this.generateExtraPayments(
        this.baseSalary,
        this.monthlyBaseSalary,
        this.monthlySalary,
        this.eraData[region].data,
        this.eraData[region].cornerMoney,
        this.eraData[region].cornerMoneyStep,
        this.extraPaymentData,
        this.eraData[region].deviatingHolidayMoneyFactor
      );

      this.chartData = {
        monthlyBaseSalary: this.monthlyBaseSalary,
        extraHoursSalary: this.extraHoursSalary,
        extraPayments: this.extraPayments,
      };

      let sum = 0;
      sum = this.monthlySalary * 12;
      this.extraPayments.forEach(
        (extraPayment: any) => (sum += extraPayment.money)
      );

      this.sum = sum;
    } else {
      this.sum = 0;
    }
  }

  generateUrl() {
    return encodeURI(
      'https://www.metallgehalt.de/gehalt/rechner?labourAgreementId=' +
        this.form.get('labourAgreementId')?.value +
        '&region=' +
        this.form.get('region')?.value +
        '&efficiencyBonus=' +
        this.form.get('efficiencyBonus')?.value +
        '&payGrade=' +
        this.form.get('payGrade')?.value +
        '&payGradeStep=' +
        this.form.get('payGradeStep')?.value +
        '&weeklyWorkingHours=' +
        this.form.get('weeklyWorkingHours')?.value +
        '&christmasMoney=' +
        this.form.get('christmasMoney')?.value
    );
  }

  generateExtraPayments(
    baseSalary: any,
    monhtlyBaseSalary: any,
    monthlySalary: any,
    payGrades: any,
    cornerMoney: string,
    cornerMoneyStep: string,
    extraPayments: Array<any>,
    deviatingHolidayMoneyFactor: number | undefined
  ) {
    return extraPayments.map((extraPayment) => {
      let factor = extraPayment.factor;

      if (extraPayment.name === 'Weihnachtsgeld') {
        factor = this.form.get('christmasMoney')?.value / 100;
      }

      if (extraPayment.name === 'Urlaubsgeld' && deviatingHolidayMoneyFactor) {
        factor = deviatingHolidayMoneyFactor;
      }

      let info = extraPayment.info.replace('{{factorPercent}}', factor * 100);
      let money = baseSalary * factor;

      if (extraPayment.calculateWith === 'cornerMoney') {
        money = payGrades[cornerMoney][cornerMoneyStep] * factor;
      } else if (extraPayment.calculateWith === 'monhtlyBaseSalary') {
        money = monhtlyBaseSalary * factor;
      } else if (extraPayment.calculateWith === 'monthlySalary') {
        money = monthlySalary * factor;
      }

      return {
        name: extraPayment.name,
        paymentMonth: extraPayment.paymentMonth,
        money: money,
        info,
      };
    });
  }

  resetCalculations() {
    this.baseSalary = 0;
    this.efficiencyBonusSalary = 0;
    this.monthlySalary = 0;
    this.extraPayments = [];
    this.sum = 0;
  }

  copied(copied: boolean) {
    if (copied) {
      this.snackBar.open('Link in die Zwischenablage kopiert!', undefined, {
        duration: 2500,
        verticalPosition: 'top',
      });
    }
  }

  compareWith(id1: any, id2: any) {
    return `${id1}` === `${id2}`;
  }
}
