import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from '@metallgehalt/shared';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { SalaryModule } from './salary-calculator/salary.module';
import { BlogModule } from './blog/blog.module';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    SalaryModule,
    BrowserAnimationsModule,
    SharedModule,
    BlogModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'de-de' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
