<h1>Impressum</h1>

<h2>Angaben gem&auml;&szlig; &sect; 5 DDG</h2>
<p>
  Maximilian Ridder<br />
  Gro&szlig;enbreden 8a<br />
  37696 Marienm&uuml;nster
</p>

<h2>Kontakt</h2>
<p>
  E-Mail: impressum&#64;metallgehalt.de
</p>

<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

<p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
