import { Component, Input } from '@angular/core';

@Component({
  selector: 'metallgehalt-blog-entry',
  templateUrl: './blog-entry.component.html',
  styleUrls: ['./blog-entry.component.scss'],
})
export class BlogEntryComponent {
  @Input() blogEntry!: any;
}
