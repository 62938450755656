<h1>Datenschutzerklärung</h1>
<h2 id="m716">Präambel</h2>
<p>
  Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als
  "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
  Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen
  Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
  "Onlineangebot“).
</p>
<p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
<p>Stand: 11. März 2023</p>
<h2>Inhaltsübersicht</h2>
<ul class="index">
  <li>Präambel</li>
  <li>Verantwortlicher</li>
  <li>Übersicht der Verarbeitungen</li>
  <li>Maßgebliche Rechtsgrundlagen</li>
  <li>Sicherheitsmaßnahmen</li>
  <li>Übermittlung von personenbezogenen Daten</li>
  <li>Löschung von Daten</li>
  <li>Einsatz von Cookies</li>
  <li>Bereitstellung des Onlineangebotes und Webhosting</li>
  <li>Kontakt- und Anfragenverwaltung</li>
  <li>Newsletter und elektronische Benachrichtigungen</li>
  <li>Webanalyse, Monitoring und Optimierung</li>
  <li>Onlinemarketing</li>
  <li>Affiliate-Programme und Affiliate-Links</li>
  <li>Änderung und Aktualisierung der Datenschutzerklärung</li>
  <li>Rechte der betroffenen Personen</li>
</ul>
<h2 id="m3">Verantwortlicher</h2>
<p>Maximilian Ridder<br />Großenbreden 8a<br />37696 Marienmünster</p>
E-Mail-Adresse:
<p><a href="mailto:datenschutz@metallgehalt.de">datenschutz&#64;metallgehalt.de</a></p>
Impressum:
<p><a href="https://www.metallgehalt.de/impressum" target="_blank">https://www.metallgehalt.de/impressum</a></p>
<h2 id="mOverview">Übersicht der Verarbeitungen</h2>
<p>
  Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
  Personen.
</p>
<h3>Arten der verarbeiteten Daten</h3>
<ul>
  <li>Bestandsdaten.</li>
  <li>Kontaktdaten.</li>
  <li>Inhaltsdaten.</li>
  <li>Vertragsdaten.</li>
  <li>Nutzungsdaten.</li>
  <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
</ul>
<h3>Kategorien betroffener Personen</h3>
<ul>
  <li>Kommunikationspartner.</li>
  <li>Nutzer.</li>
</ul>
<h3>Zwecke der Verarbeitung</h3>
<ul>
  <li>Kontaktanfragen und Kommunikation.</li>
  <li>Sicherheitsmaßnahmen.</li>
  <li>Direktmarketing.</li>
  <li>Reichweitenmessung.</li>
  <li>Tracking.</li>
  <li>Konversionsmessung.</li>
  <li>Affiliate-Nachverfolgung.</li>
  <li>Verwaltung und Beantwortung von Anfragen.</li>
  <li>Feedback.</li>
  <li>Marketing.</li>
  <li>Profile mit nutzerbezogenen Informationen.</li>
  <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
  <li>Informationstechnische Infrastruktur.</li>
</ul>
<h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
<p>
  Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie
  zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten
  ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
</p>
<ul>
  <li>
    <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
    betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
  </li>
  <li>
    <strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines
    Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
    betroffenen Person erfolgen.
  </li>
  <li>
    <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
    Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den
    Schutz personenbezogener Daten erfordern, überwiegen.
  </li>
</ul>
<p>
  Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz
  zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
  Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
  Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling.
  Des Weiteren regelt es die Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung,
  Durchführung oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der
  einzelnen Bundesländer zur Anwendung gelangen.
</p>
<h2 id="m27">Sicherheitsmaßnahmen</h2>
<p>
  Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des
  Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
  Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
  gewährleisten.
</p>
<p>
  Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
  elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer
  Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
  Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von
  Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
  Voreinstellungen.
</p>
<p>
  TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen, nutzen wir eine TLS-Verschlüsselung. Sie erkennen
  derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
</p>
<h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
<p>
  Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
  Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit
  IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen Fällen
  beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit
  den Empfängern Ihrer Daten ab.
</p>
<h2 id="m12">Löschung von Daten</h2>
<p>
  Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen
  widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
  erforderlich sind). Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
  Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
  handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
  Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
</p>
<p>
  Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen
  Verarbeitungen vorrangig gelten.
</p>
<h2 id="m134">Einsatz von Cookies</h2>
<p>
  Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten speichern und Informationen aus den Endgeräten
  auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete Funktionen
  eines Onlineangebotes speichern. Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der Funktionsfähigkeit,
  Sicherheit und Komfort von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.
</p>
<p>
  <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern
  eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das
  Speichern und das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich
  gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche Einwilligung wird gegenüber den Nutzern deutlich
  kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
</p>
<p>
  <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
  Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die
  Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
  Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung seiner
  Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich
  ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir im Laufe
  dieser Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und Verarbeitungsprozessen auf.
</p>
<p><strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:</p>
<ul>
  <li>
    <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
    Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile Applikation) geschlossen hat.
  </li>
  <li>
    <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des Endgerätes gespeichert. So können beispielsweise der
    Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe
    von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern keine expliziten Angaben zur Art und
    Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent sind
    und die Speicherdauer bis zu zwei Jahre betragen kann.
  </li>
</ul>
<p>
  <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong>Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
  widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen. Nutzer können ihren
  Widerspruch auch über die Einstellungen ihres Browsers erklären, z.B. durch Deaktivierung der Verwendung von Cookies (wobei dadurch auch die
  Funktionalität unserer Online-Dienste eingeschränkt sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken kann
  auch über die Websites <a href="https://optout.aboutads.info" target="_blank">https://optout.aboutads.info</a> und
  <a href="https://www.youronlinechoices.com/" target="_blank">https://www.youronlinechoices.com/</a> erklärt werden.
</p>
<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
<ul class="m-elements">
  <li>
    <strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong>Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein,
    in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies, bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens
    genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern verwaltet und widerrufen werden können. Hierbei wird die
    Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und die Einwilligung entsprechend der gesetzlichen
    Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe
    vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben
    zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei Jahren
    betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite der
    Einwilligung (z. B. welche Kategorien von Cookies und/oder Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät gespeichert.
  </li>
</ul>
<h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
<p>
  Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die IP-Adresse
  des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
</p>
<ul class="m-elements">
  <li>
    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus); Inhaltsdaten (z.B. Eingaben in
    Onlineformularen).
  </li>
  <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
  <li>
    <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische Infrastruktur
    (Betrieb und Bereitstellung von Informationssystemen und technischen Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen.
  </li>
  <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
</ul>
<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
<ul class="m-elements">
  <li>
    <strong>Bereitstellung Onlineangebot auf eigener/ dedizierter Serverhardware: </strong>Für die Bereitstellung unseres Onlineangebotes nutzen wir
    von uns betriebene Serverhardware sowie den damit verbundenen Speicherplatz, die Rechenkapazität und die Software;
    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
  </li>
  <li>
    <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf unser Onlineangebot wird in Form von so genannten "Server-Logfiles"
    protokolliert. Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene
    Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite)
    und im Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden,
    z.B., um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
    anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
    1 S. 1 lit. f) DSGVO); <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal 30 Tagen gespeichert und danach
    gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
    Vorfalls von der Löschung ausgenommen.
  </li>
  <li>
    <strong>E-Mail-Versand und -Hosting: </strong>Die von uns in Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang
    sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die Adressen der Empfänger sowie Absender als auch weitere Informationen betreffend
    den E-Mailversand (z.B. die beteiligten Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner zu
    Zwecken der Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich nicht verschlüsselt
    versendet werden. Im Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes
    Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den Servern, von denen sie abgesendet und empfangen werden. Wir können daher für
    den Übertragungsweg der E-Mails zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung übernehmen;
    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
  </li>
  <li>
    <strong>STRATO: </strong>Leistungen auf dem Gebiet der Bereitstellung von informationstechnischer Infrastruktur und verbundenen Dienstleistungen
    (z.B. Speicherplatz und/oder Rechenkapazitäten); <strong>Dienstanbieter:</strong> STRATO AG, Pascalstraße 10,10587 Berlin, Deutschland;
    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>
    <a href="https://www.strato.de" target="_blank">https://www.strato.de</a>; <strong>Datenschutzerklärung:</strong>
    <a href="https://www.strato.de/datenschutz" target="_blank">https://www.strato.de/datenschutz</a>;
    <strong>Auftragsverarbeitungsvertrag:</strong> Wird vom Dienstanbieter bereitgestellt.
  </li>
</ul>
<h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
<p>
  Bei der Kontaktaufnahme mit uns (z.B. per Post, Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
  Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen und etwaiger
  angefragter Maßnahmen erforderlich ist.
</p>
<ul class="m-elements">
  <li>
    <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
    Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
    Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
  </li>
  <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
  <li>
    <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback (z.B. Sammeln von
    Feedback via Online-Formular); Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
  </li>
  <li>
    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); Vertragserfüllung und vorvertragliche Anfragen (Art.
    6 Abs. 1 S. 1 lit. b) DSGVO).
  </li>
</ul>
<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
<ul class="m-elements">
  <li>
    <strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in Kontakt treten,
    verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens;
    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art.
    6 Abs. 1 S. 1 lit. f) DSGVO).
  </li>
</ul>
<h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
<p>
  Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend "Newsletter“) nur mit der Einwilligung der Empfänger
  oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die
  Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns.
</p>
<p>
  Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch bitten, einen
  Namen, zwecks persönlicher Ansprache im Newsletter, oder weitere Angaben, sofern diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.
</p>
<p>
  <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt grundsätzlich in einem sogenannte Double-Opt-In-Verfahren.
  D.h., Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig,
  damit sich niemand mit fremden E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess
  entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des Bestätigungszeitpunkts als auch
  der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert.
</p>
<p>
  <strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer
  berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten
  wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
  ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften Beachtung von Widersprüchen behalten wir uns die
  Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste (sogenannte "Blocklist") vor.
</p>
<p>
  Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu Zwecken des Nachweises seines ordnungsgemäßen
  Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an einem
  effizienten und sicheren Versandsystem.
</p>
<strong>Inhalte:</strong>
<p>Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
<ul class="m-elements">
  <li>
    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Meta-, Kommunikations-
    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus); Nutzungsdaten (z.B. besuchte Webseiten,
    Interesse an Inhalten, Zugriffszeiten).
  </li>
  <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
  <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).</li>
  <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</li>
  <li>
    <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen
    widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link zur Kündigung des Newsletters finden Sie entweder am Ende eines jeden Newsletters
    oder können sonst eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
  </li>
</ul>
<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
<ul class="m-elements">
  <li>
    <strong>Messung von Öffnungs- und Klickraten: </strong>Die Newsletter enthalten einen sogenannte "web-beacon“, d.h., eine pixelgroße Datei, die
    beim Öffnen des Newsletters von unserem Server, bzw., sofern wir einen Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im Rahmen
    dieses Abrufs werden zunächst technische Informationen, wie Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt
    des Abrufs, erhoben. Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der technischen Daten oder der Zielgruppen
    und ihres Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Diese Analyse
    beinhaltet ebenfalls die Feststellung, ob die Newsletter geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese
    Informationen werden den einzelnen Newsletterempfängern zugeordnet und in deren Profilen bis zu deren Löschung gespeichert. Die Auswertungen
    dienen uns dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte an sie anzupassen oder unterschiedliche Inhalte entsprechend
    den Interessen unserer Nutzer zu versenden. Die Messung der Öffnungsraten und der Klickraten sowie Speicherung der Messergebnisse in den Profilen
    der Nutzer <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
  </li>
</ul>
<h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
<p>
  Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der Auswertung der Besucherströme unseres Onlineangebotes und kann Verhalten,
  Interessen oder demographische Informationen zu den Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der
  Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt werden
  oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen, welche Bereiche der Optimierung bedürfen.
</p>
<p>
  Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B. unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile zu
  testen und optimieren.
</p>
<p>
  Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile, d.h. zu einem Nutzungsvorgang zusammengefasste Daten angelegt und
  Informationen in einem Browser, bzw. in einem Endgerät gespeichert und aus diesem ausgelesen werden. Zu den erhobenen Angaben gehören insbesondere
  besuchte Webseiten und dort genutzte Elemente sowie technische Angaben, wie der verwendete Browser, das verwendete Computersystem sowie Angaben zu
  Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder gegenüber den Anbietern der von uns eingesetzten Dienste
  einverstanden erklärt haben, können auch Standortdaten verarbeitet werden.
</p>
<p>
  Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
  IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie z.B.
  E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der eingesetzten Software kennen nicht die tatsächliche
  Identität der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten Angaben.
</p>
<ul class="m-elements">
  <li>
    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
  </li>
  <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
  <li>
    <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Profile mit
    nutzerbezogenen Informationen (Erstellen von Nutzerprofilen); Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies);
    Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
  </li>
  <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
  <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</li>
</ul>
<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
<ul class="m-elements">
  <li>
    <strong>Google Analytics: </strong>Webanalyse, Reichweitenmessung sowie Messung von Nutzerströmen; <strong>Dienstanbieter:</strong> Google Ireland
    Limited, Gordon House, Barrow Street, Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO);
    <strong>Website:</strong>
    <a href="https://marketingplatform.google.com/intl/de/about/analytics/" target="_blank"
      >https://marketingplatform.google.com/intl/de/about/analytics/</a
    >; <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>;
    <strong>Auftragsverarbeitungsvertrag:</strong>
    <a href="https://business.safety.google/adsprocessorterms" target="_blank">https://business.safety.google/adsprocessorterms</a>;
    <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):</strong>
    <a href="https://business.safety.google/adsprocessorterms" target="_blank">https://business.safety.google/adsprocessorterms</a>;
    <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin:
    <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>, Einstellungen für die
    Darstellung von Werbeeinblendungen:
    <a href="https://adssettings.google.com/authenticated" target="_blank">https://adssettings.google.com/authenticated</a>;
    <strong>Weitere Informationen:</strong>
    <a href="https://privacy.google.com/businesses/adsservices" target="_blank">https://privacy.google.com/businesses/adsservices</a> (Arten der
    Verarbeitung sowie der verarbeiteten Daten).
  </li>
  <li>
    <strong>Google Analytics 4: </strong>Wir verwenden Google Analytics zur Messung und Analyse der Nutzung unseres Onlineangebotes auf der Grundlage
    einer pseudonymen Nutzeridentifikationsnummer. Diese Identifikationsnummer enthält keine eindeutigen Daten, wie Namen oder E-Mail-Adressen. Sie
    dient dazu, Analyseinformationen einem Endgerät zuzuordnen, um zu erkennen, welche Inhalte die Nutzer innerhalb eines oder verschiedener
    Nutzungsvorgänge aufgerufen haben, welche Suchbegriffe sie verwendet haben, diese erneut aufgerufen haben oder mit unserem Onlineangebot
    interagiert haben. Ebenso werden der Zeitpunkt der Nutzung und deren Dauer gespeichert, sowie die Quellen der Nutzer, die auf unser Onlineangebot
    verweisen und technische Aspekte ihrer Endgeräte und Browser. Dabei werden pseudonyme Profile von Nutzern mit Informationen aus der Nutzung
    verschiedener Geräte erstellt, wobei Cookies eingesetzt werden können. In Analytics werden Daten zum geografischen Standort auf höherer Ebene
    bereitgestellt, indem die folgenden Metadaten anhand der IP-Suche erfasst werden: „Stadt“ (und der abgeleitete Breiten- und Längengrad der Stadt),
    „Kontinent“, „Land“, „Region“, „Subkontinent“ (und die ID-basierten Entsprechungen). Um den Schutz der Nutzerdaten in der EU zu gewährleisten,
    empfängt und verarbeitet Google alle Nutzerdaten über Domains und Server innerhalb der EU. Die IP-Adresse der Nutzer wird nicht protokolliert und
    standardmäßig um die letzten beiden Ziffern gekürzt. Die Kürzung der IP-Adresse findet auf EU-Servern für EU-Nutzer statt. Außerdem werden alle
    sensiblen Daten, die von Nutzern in der EU erhoben werden, gelöscht, bevor sie über EU-Domänen und -Server erfasst werden;
    <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland;
    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); <strong>Website:</strong>
    <a href="https://marketingplatform.google.com/intl/de/about/analytics/" target="_blank"
      >https://marketingplatform.google.com/intl/de/about/analytics/</a
    >; <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>;
    <strong>Auftragsverarbeitungsvertrag:</strong>
    <a href="https://business.safety.google/adsprocessorterms/" target="_blank">https://business.safety.google/adsprocessorterms/</a>;
    <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):</strong>
    <a href="https://business.safety.google/adsprocessorterms" target="_blank">https://business.safety.google/adsprocessorterms</a>;
    <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin:
    <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>, Einstellungen für die
    Darstellung von Werbeeinblendungen:
    <a href="https://adssettings.google.com/authenticated" target="_blank">https://adssettings.google.com/authenticated</a>;
    <strong>Weitere Informationen:</strong>
    <a href="https://privacy.google.com/businesses/adsservices" target="_blank">https://privacy.google.com/businesses/adsservices</a> (Arten der
    Verarbeitung sowie der verarbeiteten Daten).
  </li>
  <li>
    <strong>Google Tag Manager: </strong>Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine Oberfläche verwalten und so
    andere Dienste in unser Onlineangebot einbinden können (hierzu wird auf weitere Angaben in dieser Datenschutzerklärung verwiesen). Mit dem Tag
    Manager selbst (welches die Tags implementiert) werden daher z. B. noch keine Profile der Nutzer erstellt oder Cookies gespeichert. Google erfährt
    lediglich die IP-Adresse des Nutzers, was notwendig ist, um den Google Tag Manager auszuführen; <strong>Dienstanbieter:</strong> Google Ireland
    Limited, Gordon House, Barrow Street, Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO);
    <strong>Website:</strong> <a href="https://marketingplatform.google.com" target="_blank">https://marketingplatform.google.com</a>;
    <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>;
    <strong>Auftragsverarbeitungsvertrag:</strong>
    <a href="https://business.safety.google/adsprocessorterms" target="_blank">https://business.safety.google/adsprocessorterms</a>;
    <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):</strong>
    <a href="https://business.safety.google/adsprocessorterms" target="_blank">https://business.safety.google/adsprocessorterms</a>.
  </li>
</ul>
<h2 id="m264">Onlinemarketing</h2>
<p>
  Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings, worunter insbesondere die Vermarktung von Werbeflächen oder Darstellung von
  werbenden und sonstigen Inhalten (zusammenfassend als "Inhalte" bezeichnet) anhand potentieller Interessen der Nutzer sowie die Messung ihrer
  Effektivität fallen kann.
</p>
<p>
  Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer Datei (sogenannte "Cookie") gespeichert oder ähnliche Verfahren genutzt,
  mittels derer die für die Darstellung der vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert werden. Zu diesen Angaben können z.B.
  betrachtete Inhalte, besuchte Webseiten, genutzte Onlinenetzwerke, aber auch Kommunikationspartner und technische Angaben, wie der verwendete
  Browser, das verwendete Computersystem sowie Angaben zu Nutzungszeiten und genutzten Funktionen gehören. Sofern Nutzer in die Erhebung ihrer
  Standortdaten eingewilligt haben, können auch diese verarbeitet werden.
</p>
<p>
  Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir zur Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung
  durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden im Rahmen des Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B.
  E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der Onlinemarketingverfahren kennen nicht die
  tatsächliche Identität der Nutzer, sondern nur die in deren Profilen gespeicherten Angaben.
</p>
<p>
  Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels ähnlicher Verfahren gespeichert. Diese Cookies können später generell
  auch auf anderen Webseiten die dasselbe Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der Darstellung von Inhalten analysiert als
  auch mit weiteren Daten ergänzt und auf dem Server des Onlinemarketingverfahrensanbieters gespeichert werden.
</p>
<p>
  Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind,
  dessen Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der Nutzer mit den vorgenannten Angaben verbindet. Wir bitten darum, zu
  beachten, dass Nutzer mit den Anbietern zusätzliche Abreden, z.B. durch Einwilligung im Rahmen der Registrierung, treffen können.
</p>
<p>
  Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen
  sogenannter Konversionsmessungen prüfen, welche unserer Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben, d.h. z.B., zu einem
  Vertragsschluss mit uns. Die Konversionsmessung wird alleine zur Analyse des Erfolgs unserer Marketingmaßnahmen verwendet.
</p>
<p>Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert werden.</p>
<ul class="m-elements">
  <li>
    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations-
    und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
  </li>
  <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
  <li>
    <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher); Tracking (z.B.
    interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies); Marketing; Profile mit nutzerbezogenen Informationen (Erstellen von
    Nutzerprofilen); Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen).
  </li>
  <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
  <li>
    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
  </li>
  <li>
    <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Wir verweisen auf die Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern
    angegebenen Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die
    Möglichkeit, dass Sie Cookies in den Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes
    eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet
    angeboten werden: a) Europa: <a href="https://www.youronlinechoices.eu" target="_blank">https://www.youronlinechoices.eu</a>. b) Kanada:
    <a href="https://www.youradchoices.ca/choices" target="_blank">https://www.youradchoices.ca/choices</a>. c) USA:
    <a href="https://www.aboutads.info/choices" target="_blank">https://www.aboutads.info/choices</a>. d) Gebietsübergreifend:
    <a href="https://optout.aboutads.info" target="_blank">https://optout.aboutads.info</a>.
  </li>
</ul>
<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
<ul class="m-elements">
  <li>
    <strong>Google Ads und Konversionsmessung: </strong>Online-Marketing-Verfahren zum Zwecke der Platzierung von Inhalten und Anzeigen innerhalb des
    Werbenetzwerks des Diensteanbieters (z. B. in Suchergebnissen, in Videos, auf Webseiten usw.), so dass sie Nutzern angezeigt werden, die ein
    mutmaßliches Interesse an den Anzeigen haben. Darüber hinaus messen wir die Konversion der Anzeigen, d.h. ob die Nutzer sie zum Anlass genommen
    haben, mit den Anzeigen zu interagieren und die beworbenen Angebote zu nutzen (sog. Conversion). Wir erhalten jedoch nur anonyme Informationen und
    keine persönlichen Informationen über einzelne Nutzer; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street,
    Dublin 4, Irland; <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
    lit. f) DSGVO); <strong>Website:</strong> <a href="https://marketingplatform.google.com" target="_blank">https://marketingplatform.google.com</a>;
    <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>;
    <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie der verarbeiteten Daten:
    <a href="https://privacy.google.com/businesses/adsservices" target="_blank">https://privacy.google.com/businesses/adsservices</a>;
    Datenverarbeitungsbedingungen zwischen Verantwortlichen und Standardvertragsklauseln für Drittlandtransfers von Daten:
    <a href="https://business.safety.google/adscontrollerterms" target="_blank">https://business.safety.google/adscontrollerterms</a>.
  </li>
  <li>
    <strong>Google Adsense mit personalisierten Anzeigen: </strong>Wir nutzen den Dienst Google Adsense mit personalisierten Anzeigen, mit dessen
    Hilfe innerhalb unseres Onlineangebotes Anzeigen eingeblendet werden und wir für deren Einblendung oder sonstige Nutzung eine Entlohnung erhalten;
    <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland;
    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); <strong>Website:</strong>
    <a href="https://marketingplatform.google.com" target="_blank">https://marketingplatform.google.com</a>; <strong>Datenschutzerklärung:</strong>
    <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>;
    <strong>Weitere Informationen:</strong> Arten der Verarbeitung sowie der verarbeiteten Daten:
    <a href="https://privacy.google.com/businesses/adsservices" target="_blank">https://privacy.google.com/businesses/adsservices</a>;
    Datenverarbeitungsbedingungen für Google Werbeprodukte: Informationen zu den Diensten Datenverarbeitungsbedingungen zwischen Verantwortlichen und
    Standardvertragsklauseln für Drittlandtransfers von Daten:
    <a href="https://business.safety.google/adscontrollerterms" target="_blank">https://business.safety.google/adscontrollerterms</a>.
  </li>
  <li>
    <strong>Google Adsense mit nicht-personalisierten Anzeigen: </strong>Wir nutzen den Dienst Google Adsense mit nicht-personalisierten Anzeigen, mit
    dessen Hilfe innerhalb unseres Onlineangebotes Anzeigen eingeblendet werden und wir für deren Einblendung oder sonstige Nutzung eine Entlohnung
    erhalten; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland;
    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); <strong>Website:</strong>
    <a href="https://marketingplatform.google.com" target="_blank">https://marketingplatform.google.com</a>; <strong>Datenschutzerklärung:</strong>
    <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>;
    <strong>Weitere Informationen:</strong> Types of processing and data processed:
    <a href="https://privacy.google.com/businesses/adsservices" target="_blank">https://privacy.google.com/businesses/adsservices</a>; Google Ads
    Controller-Controller Data Protection Terms and standard contractual clauses for data transfers to third countries:
    <a href="https://business.safety.google/adscontrollerterms" target="_blank">https://business.safety.google/adscontrollerterms</a>.
  </li>
</ul>
<h2 id="m135">Affiliate-Programme und Affiliate-Links</h2>
<p>
  In unser Onlineangebot binden wir sogenannte Affiliate-Links oder andere Verweise (zu denen z.B. Suchmasken, Widgets oder Rabatt-Codes gehören
  können) auf die Angebote und Leistungen von Drittanbietern ein (zusammenfassend bezeichnet als "Affiliate-Links"). Wenn Nutzer den Affiliate-Links
  folgen, bzw. anschließend die Angebote wahrnehmen, können wir von diesen Drittanbietern eine Provision oder sonstige Vorteile erhalten
  (zusammenfassend bezeichnet als "Provision").
</p>
<p>
  Um nachverfolgen zu können, ob die Nutzer die Angebote eines von uns eingesetzten Affiliate-Links wahrgenommen haben, ist es notwendig, dass die
  jeweiligen Drittanbieter erfahren, dass die Nutzer einem innerhalb unseres Onlineangebotes eingesetzten Affiliate-Link gefolgt sind. Die Zuordnung
  der Affiliate-Links zu den jeweiligen Geschäftsabschlüssen oder zu sonstigen Aktionen (z.B. Käufen) dient alleine dem Zweck der Provisionsabrechnung
  und wird aufgehoben, sobald sie für den Zweck nicht mehr erforderlich ist.
</p>
<p>
  Für die Zwecke der vorgenannten Zuordnung der Affiliate-Links können die Affiliate-Links um bestimmte Werte ergänzt werden, die ein Bestandteil des
  Links sind oder anderweitig, z.B. in einem Cookie, gespeichert werden können. Zu den Werten können insbesondere die Ausgangswebseite (Referrer), der
  Zeitpunkt, eine Online-Kennung der Betreiber der Webseite, auf der sich der Affiliate-Link befand, eine Online-Kennung des jeweiligen Angebotes, die
  Art des verwendeten Links, die Art des Angebotes und eine Online-Kennung des Nutzers gehören.
</p>
<p>
  <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die
  Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen
  (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf
  die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
</p>
<ul class="m-elements">
  <li>
    <strong>Verarbeitete Datenarten:</strong> Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z.B. besuchte
    Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
    Identifikationsnummern, Einwilligungsstatus).
  </li>
  <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
  <li><strong>Zwecke der Verarbeitung:</strong> Affiliate-Nachverfolgung.</li>
  <li>
    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
  </li>
</ul>
<p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
<ul class="m-elements">
  <li>
    <strong>Amazon-Partnerprogramm: </strong>Affiliate-Partnerprogramm (Amazon und das Amazon-Logo sind Warenzeichen von Amazon.com, Inc. oder eines
    seiner verbundenen Unternehmen); <strong>Dienstanbieter:</strong> Amazon EU S.à r.l. (Société à responsabilité limitée), 38 avenue John F.
    Kennedy, L-1855 Luxemburg; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
    <strong>Website:</strong> <a href="https://www.amazon.de" target="_blank">https://www.amazon.de</a>; <strong>Datenschutzerklärung:</strong>
    <a href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010" target="_blank"
      >https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a
    >.
  </li>
</ul>
<h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
<p>
  Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die
  Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine
  Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.
</p>
<p>
  Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass
  die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
</p>
<h2 id="m10">Rechte der betroffenen Personen</h2>
<p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:</p>
<ul>
  <li>
    <strong
      >Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
      betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein
      auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben,
      haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung
      einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong
    >
  </li>
  <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.</li>
  <li>
    <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
    Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
  </li>
  <li>
    <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden
    Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
  </li>
  <li>
    <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen,
    dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
    Verarbeitung der Daten zu verlangen.
  </li>
  <li>
    <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
    gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen
    Verantwortlichen zu fordern.
  </li>
  <li>
    <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
    Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres
    Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen
    Daten gegen die Vorgaben der DSGVO verstößt.
  </li>
</ul>
<p class="seal">
  <a
    href="https://datenschutz-generator.de/"
    title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
    target="_blank"
    rel="noopener noreferrer nofollow"
    >Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a
  >
</p>
