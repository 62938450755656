import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalaryService {
  constructor(private http: HttpClient) {}

  getLabourAgreements() {
    return this.http.get<Array<any>>(`assets/labour-agreements.json`);
  }

  getEraData(year: number) {
    return this.http.get(`assets/${year}/era-data.json`);
  }

  getExtraPayments(year: number) {
    return this.http.get(`assets/${year}/extra-payments.json`);
  }
}
